import {
  AreaRoomsData,
  selectAreaVolumeDataSessions,
  selectAreaVolumeRoomsData,
  selectAreaVolumeSheets,
} from "@/store/area-contents-selectors";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectIsPanoExtractedFromData } from "@/store/project-selector";
import { selectActiveArea, selectAreaFor } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { selectFilteredElementsWithTags } from "@/store/tags/tags-selectors";
import { includes } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementGenericPointCloud,
  IElementGenericPointCloudStream,
  IElementImg360,
  IElementModel3dStream,
  IElementSection,
  IElementTimeSeries,
  IElementType,
  IElementTypeHint,
  areCreatedOnSameDate,
  compareById,
  isIElementGenericAnnotation,
  isIElementGenericImgSheet,
  isIElementGenericPointCloud,
  isIElementGenericPointCloudStream,
  isIElementImg360,
  isIElementImg360GrayScale,
  isIElementMarkup,
  isIElementOdometryPath,
  isIElementPanoInOdometryPath,
  isIElementPointCloudStream,
  isIElementSection,
  isIElementWithTypeAndHint,
  isValid,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import {
  PointCloudType,
  newestToOldest,
  selectAncestor,
  selectAreaDataSessions,
  selectAreaRoomsData,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectDataSessionContainedData,
  selectDatasetOrArea,
  selectIElementChildren,
  selectIElementWorldPosition,
  selectIsDataSessionAligned,
  selectPointCloudType,
  selectReferenceElement,
  selectSiblingPano,
} from "@faro-lotv/project-source";
import { uniqBy } from "es-toolkit";

export type CurrentAreaData = {
  /** The current area the user is navigating. Undefined when the user is navigating the "entire project" */
  area?: IElementSection;

  /** All the available DataSessions for this area (includes video recordings) */
  dataSessions: IElementSection[];

  /** The DataSessions that contains only 3d data */
  dataSessions3d: IElementSection[];

  /** The DataSessions that contains only 2d data (Eg. VideoRecordings)*/
  dataSessions2d: IElementSection[];

  /** The DataSessions that contains 3d and 2d data */
  dataSessions5d: IElementSection[];

  /** The available rooms time series in this area */
  roomsTimeSeries: IElementTimeSeries[];

  /** The available rooms in this area */
  roomsSections: IElementSection[];
};

/**
 * @param activeElement the element selected by the user
 * @returns The current area for the active element and all the other interesting elements for that area
 */
export function selectCurrentAreaData(activeElement?: IElement) {
  return (state: RootState): CurrentAreaData | undefined => {
    if (!activeElement) {
      return;
    }
    // When using the new area navigation the active area will be explicitly set by the user,
    // so there is no need to calculate it using the active element.
    // TODO: Remove this check when the new area navigation is released https://faro01.atlassian.net/browse/SWEB-5736
    const hasNewAreaNavigation = selectHasFeature(Features.AreaNavigation)(
      state,
    );

    const activeArea = selectActiveArea(state);
    const areaFromElement = selectAreaFor(activeElement)(state);
    const area = hasNewAreaNavigation ? activeArea : areaFromElement;

    let dataSessions: IElementSection[];

    if (hasNewAreaNavigation) {
      dataSessions = selectAreaVolumeDataSessions(area)(state);
    } else {
      if (!area) return;
      dataSessions = selectAreaDataSessions(area)(state);
    }

    const dataSessions2d: IElementSection[] = [];
    const dataSessions3d: IElementSection[] = [];
    const dataSessions5d: IElementSection[] = [];
    for (const session of dataSessions) {
      const { panos, pointClouds } = selectDataSessionContainedData(session, {
        pointCloudFilter: (pc) => selectIsPointCloudViewable(pc)(state),
      })(state);

      if (panos && pointClouds) {
        dataSessions5d.push(session);
      } else if (panos) {
        dataSessions2d.push(session);
      } else if (pointClouds) {
        dataSessions3d.push(session);
      }
    }

    let areaRoomsData: AreaRoomsData;

    if (hasNewAreaNavigation) {
      areaRoomsData = selectAreaVolumeRoomsData(area)(state);
    } else {
      if (!area) return;
      areaRoomsData = selectAreaRoomsData(area)(state);
    }

    return {
      area,
      dataSessions,
      dataSessions2d,
      dataSessions3d,
      dataSessions5d,
      roomsTimeSeries: areaRoomsData.roomsTimeSeries,
      roomsSections: areaRoomsData.roomsSections,
    };
  };
}

/** The data necessary for the sheet mode to work properly */
export type CurrentScene = {
  /** Current element selected by the user */
  activeElement: IElement;
  /** The current dataset/rooms that contains the activeElement */
  referenceElement?: IElementSection;
  /** True if this scene should use intensity data if available */
  shouldUseIntensityData: boolean;
  /** The reference 2d layout to use to navigate the current dataset/room */
  sheet?: IElementGenericImgSheet;
  /** The best rendering object relative to the active element */
  main?: IElementGenericPointCloudStream | IElementImg360;
  /** The current cad model to render in the scene depending on the user selection */
  cad?: IElementModel3dStream;
  /** The trajectories to render together with the current scene for the user to navigate */
  paths: IElementSection[];
  /** The list of rooms placeholders to show based on the current area and referenceElement */
  panos: IElementImg360[];
  /** The list of point annotations in the current scene */
  annotations: IElementGenericAnnotation[];
  /** The list of sheets available for the current area and referenceElement */
  sheets: IElementGenericImgSheet[];
};

/**
 * @returns True if the input element is not a 360 belonging to a path
 * @param element The input element
 */
function isNonTrajectoryPano(element: IElement): element is IElementImg360 {
  return (
    isIElementImg360(element) &&
    element.typeHint !== IElementTypeHint.odometryPath
  );
}

/**
 * Selects the best sheet for a given ielement.
 *
 * @param element A given element, or undefined
 * @returns The best sheet for the given element, or undefined
 */
export function selectSheetForElement(element: IElement | undefined) {
  return (state: RootState): IElementGenericImgSheet | undefined => {
    if (!element) return;
    const area = selectAreaFor(element)(state);
    if (!area) {
      return;
    }
    return selectBestImgSheet(element, area)(state);
  };
}

/**
 * @returns The first sheet child of the active element data session or area
 * @param activeElement The active element to check
 * @param area The area which the element belongs to
 */
export function selectBestImgSheet(
  activeElement: IElement,
  area?: IElementSection,
) {
  return (state: RootState): IElementGenericImgSheet | undefined => {
    const dataSessionOrFloor = selectDatasetOrArea(activeElement)(state);

    // Compute the current sheet, as the child of the data session or floor
    let sheet = selectChildDepthFirst(
      dataSessionOrFloor,
      isIElementGenericImgSheet,
    )(state);
    if (!sheet) {
      sheet = selectChildDepthFirst(area, isIElementGenericImgSheet)(state);
    }
    return sheet;
  };
}

/**
 * @returns The set of data the user want to navigate in the current area based on the selected element
 * @param activeElement The current active element of the app
 * @param activeCad The current cad model selected by the user
 * @param activeSheet The current active sheet of the app
 * @param areaData The available data in the current area
 * @param shouldUseIntensityData True to use intensity data if available for 360s
 */
export function selectCurrentScene(
  activeElement?: IElement,
  activeCad?: IElementModel3dStream,
  activeSheet?: IElementGenericImgSheet,
  areaData?: CurrentAreaData,
  shouldUseIntensityData: boolean = false,
) {
  return (state: RootState): CurrentScene | undefined => {
    if (!activeElement || !areaData) {
      return;
    }

    const panos: IElementImg360[] = [];
    const paths: IElementSection[] = [];

    const referenceElement = selectActiveElementReference(
      activeElement,
      areaData,
    )(state);

    const hasNewAreaNavigation = selectHasFeature(Features.AreaNavigation)(
      state,
    );

    const sheets = hasNewAreaNavigation
      ? selectAreaVolumeSheets(areaData.area)(state)
      : selectAllSheets(areaData.area, referenceElement)(state);
    const bestSheet = selectBestImgSheet(
      referenceElement ?? activeElement,
      areaData.area,
    )(state);

    let sheet: IElementGenericImgSheet | undefined = undefined;
    if (activeSheet && sheets.find((s) => s.id === activeSheet.id)) {
      sheet = activeSheet;
    } else if (bestSheet && sheets.find((s) => s.id === bestSheet.id)) {
      sheet = bestSheet;
    } else {
      sheet = sheets[0];
    }

    // Handle the case when the floor data is not yet loaded
    if (!referenceElement) {
      return {
        shouldUseIntensityData,
        sheet,
        paths,
        panos,
        activeElement,
        referenceElement,
        cad: activeCad,
        annotations: selectAreaAnnotations(areaData)(state),
        sheets,
      };
    }

    // Special case. If the current selected data session contains trajectories, show only those
    const referencePaths = selectChildrenDepthFirst(
      referenceElement,
      isIElementOdometryPath,
    )(state);
    if (referencePaths.length > 0) {
      paths.push(...referencePaths);
    }

    const isDataSessionAligned =
      selectIsDataSessionAligned(referenceElement)(state);

    // Collect 2d data inside 5d data sessions from all data sets that are aligned to the current one
    const dataSessionsToShow = isDataSessionAligned
      ? areaData.dataSessions5d.filter((e) =>
          areCreatedOnSameDate(e, referenceElement),
        )
      : [referenceElement];

    // Collect all 360s
    panos.push(
      ...dataSessionsToShow.flatMap((session) =>
        selectDataSet360s(session, false, shouldUseIntensityData)(state),
      ),
    );
    // Collect all trajectories
    paths.push(
      ...dataSessionsToShow.flatMap((session) =>
        selectChildrenDepthFirst(session, isIElementOdometryPath)(state),
      ),
    );

    // Only add rooms, if their area is also currently shown.
    // Rooms are likely unaligned to each other. This prevents them being shown in the wrong location when the "entire project" is shown
    if (areaData.area) {
      const panoSections = areaData.roomsTimeSeries.map((timeSeries) =>
        pickClosestInTime(
          referenceElement,
          selectIElementChildren(timeSeries.id)(state).filter(
            isIElementSection,
          ),
        ),
      );
      panos.push(
        ...panoSections
          .flatMap((panoSection) =>
            selectChildDepthFirst(panoSection, isIElementImg360)(state),
          )
          .filter(isValid),
      );
    }

    // Collect 2d data from 2d only data sessions
    for (const session of areaData.dataSessions2d) {
      const sessionPanos = selectDataSet360s(
        session,
        true,
        shouldUseIntensityData,
      )(state);
      // Check if the dataset contains paths or single 360s
      const isPath = sessionPanos.some(
        (pano) => pano.typeHint === IElementTypeHint.odometryPath,
      );
      if (
        isDataSessionAligned &&
        isPath &&
        areCreatedOnSameDate(session, referenceElement)
      ) {
        // Show video recording only of the current day
        paths.push(session);
      } else if (!isPath) {
        // Show single scans for all the days to allow Focus scans only datasets to work as rooms
        panos.push(...sessionPanos);
      }
    }

    let filteredPanos = selectFilteredElementsWithTags(
      panos,
      selectPanoTagReference,
    )(state);

    // Filter out panos that are part of the odometry path
    filteredPanos = filteredPanos.filter(
      (pano) => !isIElementPanoInOdometryPath(pano),
    );

    // It can happen that the 'paths' array contains duplicats, let's remove them
    // TODO: investigate why duplicate paths happen: https://faro01.atlassian.net/browse/SWEB-5687
    const uniquePaths = uniqBy(paths, (el) => el.id);

    return {
      shouldUseIntensityData,
      sheet,
      paths: uniquePaths,
      panos: filteredPanos,
      activeElement,
      referenceElement,
      main: selectMainModel(
        activeElement,
        areaData,
        shouldUseIntensityData,
      )(state),
      cad: activeCad,
      annotations: selectAreaAnnotations(areaData)(state),
      sheets,
    };
  };
}

/**
 * @param pano reference element to start the search
 * @returns the section element that contains the tags, which is an ancestor of the pano
 */
function selectPanoTagReference(pano: IElementImg360) {
  return (state: RootState) => selectAncestor(pano, isIElementSection)(state);
}

/**
 * @param annotation reference element to start the search
 * @returns the markup element that contains the tags, which is a child of the annotation
 */
function selectAnnotationTagReference(annotation: IElementGenericAnnotation) {
  return (state: RootState) =>
    selectChildDepthFirst(annotation, isIElementMarkup, 1)(state);
}

/**
 * @param el to check
 * @returns true if it's one of the main model we can render (360, pc or cad)
 */
export function isMainModel(
  el: IElement,
): el is IElementGenericPointCloudStream | IElementImg360 {
  return isIElementImg360(el) || isIElementGenericPointCloudStream(el);
}

/**
 * @param el to check
 * @returns true if it's a main model that is also viewable
 */
export function isViewableMainModel(el: IElement) {
  return (state: RootState) => {
    return (
      isMainModel(el) &&
      (!isIElementGenericPointCloudStream(el) ||
        selectIsPointCloudViewable(el)(state))
    );
  };
}

/**
 * @returns what main model the user is looking at (PC, Cad or 360)
 * @param activeElement current element selected by the user
 * @param areaData current data for the active area
 * @param shouldUseIntensityData true to prefer the intensity image variant of an img360
 */
function selectMainModel(
  activeElement: IElement | undefined,
  areaData: CurrentAreaData,
  shouldUseIntensityData: boolean,
) {
  return (
    state: RootState,
  ): IElementGenericPointCloudStream | IElementImg360 | undefined => {
    if (!activeElement) return;

    if (isMainModel(activeElement)) {
      // If more than one 360s is available for the current scan prefer the one
      // with the current color type used in this scene
      if (isIElementImg360(activeElement)) {
        const variants = selectPanoColorVariants(activeElement)(state);
        if (
          variants.differentTypeSiblingPano &&
          isIElementImg360GrayScale(activeElement) !== shouldUseIntensityData
        ) {
          return variants.differentTypeSiblingPano;
        }
      }
      return activeElement;
    }

    const models = [...areaData.dataSessions, ...areaData.roomsSections];

    // Check if the current element is a data set with a point cloud, so we render that point cloud
    const dataSet = selectAncestor(activeElement, (el) =>
      includes(models, el, compareById),
    )(state);
    if (dataSet) {
      // Section.DataSession -> Section.DataSet -> PointCloudStream
      const maxSearchDepth = 2;
      return selectChildDepthFirst(
        dataSet,
        (el): el is IElementGenericPointCloudStream | IElementImg360 =>
          isViewableMainModel(el)(state),
        maxSearchDepth,
      )(state);
    }
  };
}

/**
 * @param activeElement to compute the reference element for
 * @param areaData all the data in the current area
 * @returns the reference element (the one containing time information) from the active element if it exists
 */
export function selectActiveElementReference(
  activeElement: IElement | undefined,
  areaData: CurrentAreaData,
) {
  return (state: RootState): IElementSection | undefined => {
    if (!activeElement) return;
    // All the possible elements that can be used as a time reference (data sessions, rooms, or bim models)
    // Maps to what the user can select in the project tree or in the various data selections uis (Eg. TimeDropDown)
    const referenceGroup = [
      ...areaData.dataSessions,
      ...areaData.dataSessions2d,
      ...areaData.roomsSections,
    ].sort(newestToOldest);

    // Check if the activeElement is part of one of the reference element
    let reference = selectAncestor(activeElement, (el) =>
      includes(referenceGroup, el, compareById),
    )(state);
    if (reference && isIElementSection(reference)) {
      return reference;
    }

    // Here the active element is outside the reference group, find the newest reference element that is contained
    // in the active element (To handle the user selecting an Area, or a Time Series)
    reference = referenceGroup.find((ref) =>
      selectAncestor(
        ref,
        (ancestor) => ancestor.id === activeElement.id,
      )(state),
    );
    if (reference && isIElementSection(reference)) {
      return reference;
    }

    // Pick the closest in time data session to the activeElement
    return selectReferenceElement(activeElement, referenceGroup)(state);
  };
}

/**
 * @param session to check
 * @param allowTrajectories true to allow trajectories panos
 * @param shouldUseIntensityData true to use intensity panos if available
 * @returns the representative 360s contained in the session, favoring color or grayscale ones over the other
 * based on the selected preference by the user
 */
export function selectDataSet360s(
  session: IElement,
  allowTrajectories: boolean,
  shouldUseIntensityData: boolean,
) {
  return (state: RootState): IElementImg360[] => {
    const panos = selectChildrenDepthFirst(
      session,
      allowTrajectories ? isIElementImg360 : isNonTrajectoryPano,
    )(state);

    return panos.filter((pano, _, array) => {
      const typeHint = shouldUseIntensityData
        ? IElementTypeHint.grayScale
        : null;

      return (
        pano.typeHint === typeHint ||
        !array.some(
          (el) => el.parentId === pano.parentId && el.typeHint === typeHint,
        )
      );
    });
  };
}

type IsPanoWithIntensity = {
  /** Flag to know if the element is a panorama image with intensity. If false then the element is a colored pano. */
  isPanoWithIntensity?: boolean;

  /** Sibling panorama image of the passed element. It has a different typeHint from the passed element. */
  differentTypeSiblingPano?: IElementImg360;
};

/**
 * @param activeElement reference element to check
 * @returns an object containing a flag to know if the passed element is a color or grayscale pano,
 * and the id of the other color version available for that pano.
 */
export function selectPanoColorVariants(activeElement?: IElement) {
  return (state: RootState): IsPanoWithIntensity => {
    if (!activeElement || !isIElementImg360(activeElement)) {
      return {};
    }

    const siblingPano = selectSiblingPano(
      activeElement,
      (el) => el.typeHint !== activeElement.typeHint,
    )(state);

    return {
      isPanoWithIntensity: isIElementImg360GrayScale(activeElement),
      differentTypeSiblingPano: siblingPano,
    };
  };
}

/**
 * @returns the annotations for the area, coming from the bi-tree or from the datasets
 * @param area to compute the annotations for
 */
function selectAreaAnnotations(area: CurrentAreaData) {
  return (state: RootState): IElementGenericAnnotation[] => {
    // Find datasets that are not inside the area
    const captureTreeDataSets = area.dataSessions.filter(
      (element) =>
        !selectAncestor(element, ({ id }) => id === area.area?.id)(state),
    );
    const annotationsRoots = [area.area, ...captureTreeDataSets];
    const annotations = annotationsRoots
      .map((element) =>
        selectChildrenDepthFirst(element, isIElementGenericAnnotation)(state),
      )
      .flat();

    return selectFilteredElementsWithTags(
      annotations,
      selectAnnotationTagReference,
    )(state);
  };
}

/**
 * @returns All the sheets children of the reference element or the area.
 * @param area The area to which the reference element belongs
 * @param referenceElement The reference element
 */
function selectAllSheets(area?: IElementSection, referenceElement?: IElement) {
  return (state: RootState): IElementGenericImgSheet[] => {
    // Get all sheets children of the reference element
    const sheets = selectChildrenDepthFirst(
      referenceElement,
      isIElementGenericImgSheet,
    )(state);
    // Get all sheets direct children of the area group
    const areaGroup = selectChildDepthFirst(area, (el) =>
      isIElementWithTypeAndHint(el, IElementType.group, IElementTypeHint.area),
    )(state);
    sheets.push(
      ...selectChildrenDepthFirst(areaGroup, isIElementGenericImgSheet)(state),
    );
    return sheets;
  };
}

/**
 * @returns whether a given point cloud should be viewable in the Viewer, or hidden from the user completely.
 * @param pc the point cloud to check
 */
export function selectIsPointCloudViewable(
  pc: IElementGenericPointCloudStream | IElementGenericPointCloud,
) {
  return (state: RootState): boolean =>
    selectPointCloudType(pc)(state) !== PointCloudType.singleScan;
}

/**
 * @returns whether a given IElement is a viewable point cloud stream
 * @param element the IElement to check
 */
export function selectIsElementViewablePointCloudStream(element?: IElement) {
  return (state: RootState) =>
    !!element &&
    isIElementPointCloudStream(element) &&
    selectIsPointCloudViewable(element)(state);
}

/**
 * @returns whether a given IElement is a viewable in the Viewer, or whether it should be hidden from the user completely.
 * @param element the IElement to check
 */
export function selectIsElementViewable(element?: IElement) {
  return (state: RootState) => {
    if (!element) return false;

    if (
      isIElementGenericPointCloudStream(element) ||
      isIElementGenericPointCloud(element)
    ) {
      return selectIsPointCloudViewable(element)(state);
    }

    return true;
  };
}

export type WaypointAltitudeRange = {
  /** The lowest(y) waypoint in the current scene */
  lowest: number;

  /** The highest(y) waypoint in the current scene */
  highest: number;
};

/**
 * Only panos that are coming from a dataset will be used to calculate the altitude range.
 * At least two valid panos are required to calculate the range.
 * Return a valid range only if the altitude difference is at least 1 meter.
 *
 * @param panos The list of pano images to consider for the range
 * @returns The highest and lowest panos in the list or undefined if no valid range is available
 */
export function selectPanoAltitudeRange(panos?: IElementImg360[]) {
  return (state: RootState): WaypointAltitudeRange | undefined => {
    const MIN_VALID_RANGE = 1;
    // Take into account for the range only the panos, from datasets, that have a valid position
    const filteredPanos = panos?.filter((pano) =>
      selectIsPanoExtractedFromData(pano)(state),
    );

    // Skip the calculation if there are not enough panos to calculate the range
    if (!filteredPanos?.length || filteredPanos.length < 2) return;

    const range = filteredPanos.reduce(
      (prev, pano) => {
        const position = selectIElementWorldPosition(pano.id)(state);
        if (position[1] < prev.lowest) {
          prev.lowest = position[1];
        }
        if (position[1] > prev.highest) {
          prev.highest = position[1];
        }
        return prev;
      },
      {
        lowest: Number.POSITIVE_INFINITY,
        highest: Number.NEGATIVE_INFINITY,
      },
    );
    if (range.highest - range.lowest < MIN_VALID_RANGE) return;
    return range;
  };
}
