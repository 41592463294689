import { CadModelObject, PointCloudObject } from "@/object-cache";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Box3 } from "three";
import { use3DmodelsBoundingBox } from "./use-object-bounding-box";
import { sheetCorners2Box, useSheetCorners } from "./use-sheet-corners";

/**
 *
 * @param pointCloud The point coud
 * @param cad The CAD model
 * @param sheet the floorplan
 * @returns The bounding box containing all the argument objects
 */
export function useClippableObjectsBox(
  pointCloud: PointCloudObject | null,
  cad: CadModelObject | null | Error,
  sheet: IElementGenericImgSheet | undefined,
): Box3 {
  const modelsBox = use3DmodelsBoundingBox(pointCloud, cad);
  const sheetCorners = useSheetCorners(sheet);

  return useMemo(() => {
    return sheetCorners2Box(sheetCorners, modelsBox.clone());
  }, [modelsBox, sheetCorners]);
}
