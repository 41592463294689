import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { useCenterCameraOnPointCloud } from "@/hooks/use-center-camera-on-pointcloud";
import { useCurrentScene } from "@/modes/mode-data-context";
import { PointCloudWithOpacity } from "@/modes/overview-mode/overview-point-cloud";
import { useCached3DObject } from "@/object-cache";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, activateTool } from "@/store/ui/ui-slice";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  BoxControls,
  BoxControlsRef,
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Matrix4, Object3D, Plane, Vector3 } from "three";
import { OBB } from "three-stdlib";

const TEMP_OBB = new OBB();

/** @returns the scene for the clipping box mode */
export function ClippingBoxModeScene(): JSX.Element {
  const dispatch = useAppDispatch();
  const activeTool = useAppSelector(selectActiveTool);

  useEffect(() => {
    if (activeTool !== ToolName.clipping) {
      dispatch(activateTool(ToolName.clipping));
    }
  }, [dispatch, activeTool]);

  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "Clipping Box Mode requires a PointCloud as the main element",
  );

  const camera = useThree((s) => s.camera);

  const pointCloudObject = useCached3DObject(main);

  const { resetBoxEvent, hasUserInteracted, setHasUserInteracted } =
    useBoxControlsContext();

  const {
    box: modelBB,
    cloudTransform,
    target,
  } = useCenterCameraOnPointCloud(camera, pointCloudObject);

  /** Position of the clipping box */
  const [boxPosition, setBoxPosition] = useState(
    modelBB.getCenter(new Vector3()),
  );

  /** Scale of the clipping box */
  const [boxScale, setBoxScale] = useState(
    modelBB.max.clone().sub(modelBB.min),
  );

  /** Function to call when the reset clipping box button is clicked */
  useTypedEvent(
    resetBoxEvent,
    useCallback(() => {
      setBoxPosition(modelBB.getCenter(new Vector3()));
      setBoxScale(modelBB.max.clone().sub(modelBB.min));
    }, [modelBB]),
  );

  const [controlsEnabled, setControlsEnabled] = useState(true);

  // Reset the boolean to check if the user has interacted with the box controls on cleanup,
  // So that the help message can be shown on every entry to the clipping box mode
  useEffect(() => {
    return () => {
      setHasUserInteracted(false);
    };
  }, [setHasUserInteracted]);

  const onInteractionStarted = useCallback(() => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }
    setControlsEnabled(false);
  }, [hasUserInteracted, setHasUserInteracted]);

  const [clippingPlanesPreview, setClippingPlanesPreview] = useState<Plane[]>();
  const { setClippingPlanes } = useBoxControlsContext();

  const boxRef = useRef<BoxControlsRef>();

  // Updates the preview clipping box and calculates the local clipping planes for the clipping box state
  const onClippingPlanesChanged = useCallback(
    (clippingPlanes: Plane[]) => {
      // The clipping planes for the preview should always be defined and in world space
      setClippingPlanesPreview(clippingPlanes);

      if (!boxRef.current) return;
      // Compute an OBB (Oriented Bounding Box) to check if the user defined box is valid and intersect the PC box
      const trx = boxRef.current.boxMatrixWorld;
      TEMP_OBB.center.set(0, 0, 0);
      TEMP_OBB.halfSize.set(0.5, 0.5, 0.5);
      TEMP_OBB.rotation.identity();
      TEMP_OBB.applyMatrix4(trx);
      // If any of the obb halfSize is 0.01 then the box is too small
      // 0.01 is the distance at which two box handle will overlap
      const MIN_SIZE = 0.01;
      if (
        TEMP_OBB.halfSize.x < MIN_SIZE ||
        TEMP_OBB.halfSize.y < MIN_SIZE ||
        TEMP_OBB.halfSize.z < MIN_SIZE
      ) {
        setClippingPlanes(undefined);
        return;
      }
      const hit = TEMP_OBB.intersectsBox3(modelBB);
      // If there's no intersection we don't have a valid set of planes to extract a volume
      if (!hit) {
        setClippingPlanes(undefined);
        return;
      }

      const invModelTransform = new Matrix4()
        .fromArray(cloudTransform.worldMatrix)
        .invert();
      const planes = clippingPlanes.map((plane) =>
        plane.clone().applyMatrix4(invModelTransform),
      );
      setClippingPlanes(planes);
    },
    [modelBB, cloudTransform.worldMatrix, setClippingPlanes],
  );

  const interactiveObjects = useMemo(
    () => [pointCloudObject],
    [pointCloudObject],
  );

  return (
    <>
      <PointCloudWithOpacity
        pointCloud={pointCloudObject}
        visible
        clippingPlanes={clippingPlanesPreview}
      />
      <BoxControls
        ref={boxRef}
        position={boxPosition}
        size={boxScale}
        clipInside={false}
        enableRotateX={false}
        enableRotateY={false}
        onInteractionStarted={onInteractionStarted}
        onInteractionStopped={() => setControlsEnabled(true)}
        clippingPlanesChanged={onClippingPlanesChanged}
      />
      <ExplorationControls
        target={target}
        enabled={controlsEnabled}
        obstacles={interactiveObjects}
      />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={pointCloudObject} />
        <FilteredRenderPass
          filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
